import styles from "./style.module.css";

export const SkillMarks = ({ category, skillMarks }) => {
  return (
      <div className="table-responsive">
        <table className={`table table-hover ${styles.customTable}`}>
          <thead>
            <tr>
              <th scope="col">Compétence</th>
              <th scope="col" className="text-center">Niveau</th>
            </tr>
          </thead>
          <tbody>
            {(skillMarks || []).map((skillMark, index) => (
              <tr key={index}>
                <td>{skillMark?.skill?.label ?? skillMark?.label}</td>
                <td className={`text-center ${styles.level}`}>{skillMark.level ?? '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  );
};
