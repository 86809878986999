import { FiLinkedin, FiMail, FiMapPin, FiPhone } from "react-icons/fi";
import ProfileLogo from "../../ProfileLogo";
import { TextField } from "../../TextField";
export const IdentityCard = ({ currentConsultant, match }) => {
  const { job, missionPreferences, unavailabilityTerm } = currentConsultant || {};
  const routeToBlankLinkedinPage = (currentConsultant) => {
    window.open(currentConsultant.user.linkedInUri);
  };

  return (
    <div className="card d-flex flex-column gap-3 p-4">
      <div className="d-flex flex-row gap-3 justify-content-center">
        <div style={{ width: "150px", height: "150px" }}>
          {currentConsultant?.profilePicture ? (
            <ProfileLogo profilePicture={currentConsultant.profilePicture} />
          ) : (
            <img
              src="/wow-circle-white.svg"
              width={"100%"}
              height={"100%"}
              alt="candidature img"
            />
          )}
        </div>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="wow-subtitle bold">
          {`${currentConsultant?.user?.firstName}
                          ${currentConsultant?.user?.lastName}`}
        </div>
        <div className="small-text">
          {currentConsultant?.user?.birthDate &&
            new Date(currentConsultant?.user?.birthDate).toLocaleDateString()}
        </div>
      </div>
      <div className="small-text px-2">
        <FiMail /> {currentConsultant?.user?.email}
      </div>
      <div className="small-text px-2">
        <FiPhone /> {currentConsultant.user?.phoneNumber}
      </div>
      {currentConsultant?.user?.linkedInUri && (
        <div
          onClick={() => routeToBlankLinkedinPage(currentConsultant)}
          className="small-text px-2 text-decoration-underline cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          <FiLinkedin /> {currentConsultant?.user?.linkedInUri}
        </div>
      )}
      <div className="small-text px-2">
        <FiMapPin />
        {currentConsultant?.user?.address &&
          `${currentConsultant?.user?.address.street}, 
                      ${currentConsultant?.user?.address.postalCode} 
                      ${currentConsultant?.user?.address.city}`}
      </div>
      <div className="d-flex flex-column">
        {job && (
          <TextField
            label="Métier"
            value={`${job.label} ${job.jobLevel || ''}`}
          />
        )}

        <TextField
          label="TJM"
          value={missionPreferences?.dailyRateAverage ? 
            `${missionPreferences.dailyRateAverage}€` : 
            undefined
          }
        />

        <TextField
          label="Date de disponibilité"
          value={unavailabilityTerm ? 
            new Date(unavailabilityTerm).toLocaleDateString() : 
            undefined
          }
        />

        <TextField
          label="Mobilité"
          value={missionPreferences?.workSites?.join(", ")}
        />

        <TextField
          label="Rythme de travail souhaité"
          value={missionPreferences?.workPreference}
        />
      </div>
    </div>
  );
};
