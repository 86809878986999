import { TextField } from "../../TextField";

export const AdminWowCard = ({ currentConsultant }) => {
  return (
    <div className="card h-100 gap-3 p-4">
      <div className="wow-subtitle bold">Admin WOW</div>

      <div className="d-flex flex-column">
      <TextField
        label={"Wow team member"}
        value={
          currentConsultant?.affiliateManager &&
          `${currentConsultant.affiliateManager?.firstName} ${currentConsultant.affiliateManager?.lastName}`
        }
      />

      <TextField
        label={"Bureau Wow"}
        value={currentConsultant?.user?.wowOffice}
      />

      <TextField
        label={"Box onboarding"}
        value={currentConsultant?.onBoardingBoxSent ? "Envoyée" : "Non envoyée"}
      />

      {currentConsultant?.society?.paymentDelay && (
        <TextField
          label={"Délai de paiement"}
            value={`${currentConsultant?.society?.paymentDelay} jours`}
          />
        )}
      </div>
    </div>
  );
};
