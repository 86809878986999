import { SkillMarks } from "../SkillMarks";

export const Skills = ({ currentConsultant }) => {
  return (
    <div className="d-flex flex-row gap-4">
      {currentConsultant?.consultantSkillMarks?.length > 0 && (
        <div className="card p-4 gap-3 w-100">
          <div className="wow-subtitle bold">Compétences</div>
          <SkillMarks
            category={"Compétences(s)"}
            skillMarks={currentConsultant?.consultantSkillMarks}
          />
        </div>
      )}
      {currentConsultant?.spokenLanguages?.length > 0 && (
        <div className="card p-4 gap-3 w-100">
          <div className="wow-subtitle bold">Langues</div>
          <SkillMarks
            category={"Langue(s)"}
            skillMarks={currentConsultant?.spokenLanguages}
          />
        </div>
      )}
    </div>
  );
};
