import { TextField } from "../../TextField";

export const References = ({ references }) => {
  return (
    <div className="card w-100 gap-2 p-4">
      <h2 className="wow-subtitle bold">Références professionnelles</h2>
      
      {references && references.length > 0 ? (
        references.map((reference) => (
          <div 
            key={reference.id} 
            className="border rounded p-3 mb-3 bg-light"
          >
            <div className="row">
              <div className="col-md-6">
                <TextField
                  label="Nom complet"
                  value={`${reference.lastName} ${reference.firstName}`}
                />
                <TextField
                  label="Email"
                  value={reference.email}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  label="Téléphone"
                  value={reference.phoneNumber}
                />
                <TextField
                  label="Description"
                  value={reference.description}
                />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="small-text">Aucune référence</div>
      )}
    </div>
  );
};
