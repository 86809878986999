import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { IdentityCard } from "../../../Components/ConsultantIdentite/IdentityCard";
import { MissionSelection } from "../../../Components/ConsultantIdentite/MissionSelection";
import { Skills } from "../../../Components/ConsultantIdentite/Skills";
import { SocietyIdentity } from "../../../Components/ConsultantIdentite/SocietyIdentity";
import { WatchMode } from "../../../Components/ConsultantIdentite/WatchMode";
import SocietyPopup from "../../../Components/SocietyPopup";
import toast from "../../../Helpers/toast";
import { attachSociety } from "../../../Services/Consultant";
import { patchConsultantState } from "../../../Services/Dashboard";
import { AdminWowCard } from "../../../Components/ConsultantIdentite/AdminWowCard";
import Timeline from "../../../Components/Timeline";
import { References } from "../../../Components/ConsultantIdentite/References";
const IdentiteConsultant = ({ match }) => {
  const [openSocietyPopup, setOpenSocietyPopup] = useState(false);
  const currentConsultant = useSelector(
    (state) => state.userReducer.consultant
  );

  const [consultantStates, setConsultantStates] = useState([
    {
      topLabel: "Désactivé",
      value: "DEACTIVATED",
      color: "var(--danger)",
      checked: false,
      disabled: false,
    },
    {
      topLabel: "En attente",
      value: "PENDING",
      color: "var(--blue-ny)",
      checked: false,
      disabled: false,
    },
    {
      topLabel: "Qualification",
      value: "QUALIFICATION",
      color: "#2BA1B8",
      checked: false,
      disabled: false,
    },
    {
      topLabel: "Activé",
      value: "ACTIVATED",
      color: "var(--primary-green)",
      checked: false,
      disabled: false,
    },
  ]);

  const handleConsultantStateChange = (consultant) => {
    const updatedStates = consultantStates.map((state) => {
      if (!!!consultant?.state && state.value === "PENDING") {
        return { ...state, checked: true };
      }
      if (state.value === consultant?.state) {
        return {
          topLabel: state.topLabel,
          botLabel: consultant?.stateUpdatedBy
            ? `${consultant?.stateUpdatedBy?.fullName}${
                consultant?.stateUpdatedAt
                  ? " - " +
                    new Date(consultant.stateUpdatedAt).toLocaleDateString()
                  : ""
              }`
            : new Date(consultant.createdAt).toLocaleDateString(),
          value: state.value,
          color: state.color,
          checked: true,
          disabled: true,
        };
      }
      return { ...state, checked: false, disabled: false, botLabel: undefined };
    });

    setConsultantStates(updatedStates);
  };

  useEffect(() => {
    handleConsultantStateChange(currentConsultant);
  }, [currentConsultant]);

  const handleCheckboxChange = (value) => {
    patchConsultantState(match.params.id, { state: value })
      .then((res) => {
        handleConsultantStateChange(res.data);
        toast("success", "L'état du candidat a été mis a jour");
      })
      .catch((e) =>
        toast("error", "Erreur lors de la modification de l'état du candidat")
      );
  };

  const handleSociety = (society) => {
    attachSociety(currentConsultant.id, society.id).then(
      (res) => {
        toast(
          "success",
          `Le consultant a été rattaché à la société ${society.label}`
        );
        setTimeout(() => {
          window.location.reload();
        }, 800);
      },
      (reason) => {
        toast("error", reason.message);
      }
    );
    setOpenSocietyPopup(false);
  };

  return (
    <div className="row gap-4">
      <WatchMode />
      {openSocietyPopup && (
        <SocietyPopup
          show={openSocietyPopup}
          onClose={(e) => setOpenSocietyPopup(false)}
          onValidate={handleSociety}
        />
      )}
      <Timeline
        items={consultantStates}
        onCheckboxChange={handleCheckboxChange}
      />

      {currentConsultant && (
        <div className="d-flex flex-column gap-4">
          <div className="d-flex gap-4 ">
            <div className="w-100 flex-grow-1 d-flex flex-column gap-4">
                <IdentityCard currentConsultant={currentConsultant} match={match} />
            </div>
            <div className="w-100 flex-grow-1 d-flex flex-column gap-4">
              <div className="flex-grow-1 h-100">
                <AdminWowCard currentConsultant={currentConsultant} />
              </div>
              <div className="flex-grow-1 h-100">
                <SocietyIdentity currentConsultant={currentConsultant} />
              </div>
            </div>
          </div>
          <Skills currentConsultant={currentConsultant} />
          <References references={currentConsultant.references} />
          <MissionSelection currentConsultant={currentConsultant} />
        </div>
      )}
    </div>
  );
};

export default withRouter(IdentiteConsultant);
